import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { Modal } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "font-awesome/css/font-awesome.css"
import logo from "../../static/blossomorwilt2.jpg"

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }
  showModal = () => {
    this.setState({
      showModal: true
    });
  };
  redirectTo = () => {
    this.showModal();
    setTimeout(() => {
     window.location = `https://www.instagram.com/efsblossomshack/`;
    }, 2500);
  }
  render() {
    return(
    <>
      <Helmet>
        <title>E&amp;F's Blossom Shack</title>
      </Helmet>
      <Modal show={this.state.showModal}>
        <Modal.Body>
            <div className="container-fluid">
              <div className="row">
                <div className="container text-center">
                  <h4>Redirecting to Instagram</h4>
                  <div className="spinner-border text-success">
                  <i className="fa fa-leaf"></i>
                  </div>
                </div>
              </div>
            </div>
        </Modal.Body>
      </Modal>
      <div className="container-fluid">
        <div className="row">
          <div className=" container text-center">
          <h1>E&amp;F's Blossom Shack</h1>
          <h2>Reviewing Beauty Trends, Whether They Blossom or Wilt.</h2>
          <p>Under Construction - Check back soon!</p>
          </div>
        </div>
        <div className="row">
          <button
            className="btn btn-dark btn-lg btn-block"
            onClick={this.redirectTo}
          >
            <i className="fa fa-instagram"></i> <span>Follow Us</span>
          </button>
        </div>
        <div className="row">
          <img
            src={logo}
            alt="Blossom or Wilt"
            className="img-fluid mx-auto d-block"
          />
        </div>
        <footer>
          <div className="container text-center">
        &copy; 2020 E&amp;F's Blossom Shack
        </div>
        </footer>
      </div>
    </>
  )}
}

export default Home;